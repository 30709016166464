<template>
    <div
        :id="blok.anchor"
        class="ui-advanced-title"
        :class="componentClasses"
        :style="componentStyles"
        data-test="ui-advanced-title"
    >
        <NuxtImg
            v-if="blok.image?.filename"
            :src="blok.image.filename"
            :title="blok.image.title"
            :alt="blok.image.alt"
            :style="imageStyles"
            :class="imageClasses"
            provider="storyblok"
            format="webp"
            loading="lazy"
            densities="x1"
        />
        <component :is="blok.headingType ? blok.headingType : 'h2'" :style="headingStyles">
            <nuxt-link
                v-if="blok.link.url || blok.link.cached_url"
                :to="getInternalLink(blok.link)"
                :target="blok.link.target || '_self'"
                :rel="blok.rel.join(' ')"
                external
            >
                <span :style="buildTextStyles(blok.font?.[0] as IComponentFont)" v-html="blok.text"></span>
            </nuxt-link>
            <span v-else :style="buildTextStyles(blok.font?.[0] as IComponentFont)" v-html="blok.text"></span>
        </component>
    </div>
</template>

<script setup lang="ts">
import type { IComponentFont } from '~ui/types/ComponentStyles';
import { EHideOnType, EImagePositions, EUnderlineTypes } from '~ui/types/enums/ComponentStyle';
import type { ICoreAdvancedTitle } from '~ui/types/components/CoreAdvancedTitle';
import { buildTextStyles } from '~ui/utils/styleBuilder';
import { useIsMobileView } from '~ui/composables/isMobileView';
import screens from '#tailwind-config/theme/screens';

const { blok } = defineProps<{ blok: ICoreAdvancedTitle }>();
const { isMobileView } = useIsMobileView(screens.sm);
const headingStyles = computed(() => ({
    ...buildFonts(blok.font?.[0] as IComponentFont),
    'line-height': blok.font?.[0]?.fontSize && blok.font?.[0]?.fontSize < 100 ? `${blok.font?.[0]?.fontSize}%` : null,
}));

const componentStyles = computed(() => ({
    'justify-content': blok.image?.filename && blok.font?.[0]?.alignment ? blok.font?.[0].alignment : null,
    '--font-color': headingStyles.value?.['--font-color'],
    'text-align': isMobileView.value ? blok.mobileAlignment : blok.font?.[0]?.alignment,
    'padding-top': blok.paddingY ? `${blok.paddingY}px` : null,
    'padding-bottom': blok.paddingY ? `${blok.paddingY}px` : null,
    'padding-left': blok.paddingX ? `${blok.paddingX}px` : null,
    'padding-right': blok.paddingX ? `${blok.paddingX}px` : null,
}));
const componentClasses = computed(() => ({
    'has-image': blok.image?.filename,
    'has-divider': blok.font[0]?.underline === EUnderlineTypes.DIVIDER,
    'has-underline-border': blok.font[0]?.underline === EUnderlineTypes.BORDER,
}));
const imageStyles = computed(() => ({
    width: blok.imageSize ? `${blok.imageSize}px` : null,
}));
const imageClasses = computed(() => ({
    'hide-desktop': blok.imageHideOn === EHideOnType.Desktop,
    'hide-mobile': blok.imageHideOn === EHideOnType.Mobile,
    'position-left': blok.imagePosition === EImagePositions.LEFT,
    'position-right': blok.imagePosition === EImagePositions.RIGHT,
}));
</script>

<style scoped lang="postcss">
.ui-advanced-title {
    &.has-image {
        @apply flex items-center gap-x-4;
        .position-right {
            @apply order-2;
        }
    }

    h1 {
        @apply text-4xl;
    }
    h2 {
        @apply text-4xl;
    }
    h3 {
        @apply text-3xl;
    }
    h4 {
        @apply text-2xl;
    }
    h5 {
        @apply text-xl;
    }
    h6 {
        @apply text-lg;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply leading-[normal];
    }

    &.has-underline-border {
        @apply border-b-[1px];
        border-color: var(--font-color, #000);
    }
    &.has-divider {
        @apply relative;
        &:before {
            @apply content-[""] h-[3px] w-full absolute bottom-px left-0;
            background-color: var(--font-color, #000);
        }
    }
    .hide-desktop {
        @apply md:hidden;
    }
    .hide-mobile {
        @apply max-md:hidden;
    }
}
</style>
